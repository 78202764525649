$body-bg: #f2f3f8;
$body-color: #646c9c;


$font-family-sans-serif: 'Poppins', sans-serif;

$primary: #5867dd;
$secondary: #e1e1ef;
$success: #0abb87;
$info: #5578ea;
$warning: #ffb822;
$danger: #fd397a;
$light: #f8f9fa;
$dark: #343a40;



$dropzone-active-border-color: #00cae7;
$dropzone-border-color: #EEE;
$dropzone-bg-color: #e7fdff;
$dropzone-p-color:lightgray;


$sidebar-bg-color: #FFF;
$navbar-brand-bg-color: #FFF;
$nav-link-color: #231d45;
$menu-separator-color: #4c4e6f;
$nav-link-active-color: white;
$nav-link-active-bg-color: #1a1a27;

$top-menu-toggle-color: #FFF;
$top-menu-toggle-bg-color: #231d45;

$top-navbar-dropdown-item-color: #6c7293;


$top-navbar-user-dropdown-a-color: #6c7293;
$top-navbar-user-dropdown-a-hover-bg-color: rgba(77, 89, 149, 0.06);

$top-navbar-dropdown-menu-box-shadow-color: rgba(82, 63, 105, 0.15);
$top-navbar-dropdown-menu-border-color: #DDD;

$top-navbar-nav-link-color: #6c7293;
$top-navbar-nav-link-hover-color: #5d78ff;
$top-navbar-nav-link-hover-bg-color: rgba(77, 89, 149, 0.06);

$column-container-aside-color: white;

$btn-light-dark-bg-color: rgba(40, 42, 60, 0.1);
$btn-light-dark-color: #282a3c;

$btn-light-dark-hover-bg-color: #282a3c;
$btn-light-dark-hover-color: white;

$starter-list-card-bg-color: transparent;
$starter-list-card-border-color: #dedfe0;
$starter-list-card-box-shadow-color: rgba(0,0,0,0.1);

$starter-list-table-th-bg-color: white;
$starter-list-table-th-color: #5867dd;


$starter-list-search-form-input-group-text-bg-color: white;

$starter-list-table-tr-starter-list-item-row-td-bg-color: white;

$starter-list-table-tr-starter-list-item-row-td-odd-bg-color: rgb(250,250,250);

$starter-list-table-tr-starter-list-item-row-td-a-color: #646C9C;
$starter-list-table-tr-hover-td-bg-color: rgba(88, 103, 221, 0.05);

.card {
    box-shadow: 0px 0px 30px rgba(0,0,0,0.1);
}

.card-header {
    padding: 20px;
    color: $body-color;
    background-color: white;
    font-weight: 600;
    -webkit-font-smoothing: antialised;
}

.modal-header {
    align-items: center;
}

.modal-title {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
}

.alert-danger {
    background-color: $danger;
    border-color: $danger;
    color: white;
}

.alert-success {
    background-color: $success;
    border-color: $success;
    color: white;
    font-weight: 500;
}

label.form-label, label.field-title {
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    color: transparentize($color: $body-color, $amount: 0.5);
}